@import "../../base/functions";

.input__input{
    @include size-component(100%,100%);
    @include border-component(0,50px,none);
    @include input-component();
    @include text-component(center, none, none, none);
}

.input__div-label{
    @include flex-component(flex,none,none, none);
}

.input__div-label-required{
    @include make-col(1);
}

.input__div-label-required-text{
    @include make-col(10);
}

.input__label-required{
    @include margin-component(none, none, $spacer , none);
    @include text-component(none, $colorFooter, bold, none);
}

.input__text-area{
    @include size-component(100%,100%);
    @include input-component();
    resize: none;
    @include border-component(0,10px,none);
    @include text-component(center, none, none, none);

}

.input__select{
    @include size-component(100%,100%);
    @include border-component(0,50px,none);
    @include input-component();
    text-align-last: center;
    @include text-component(none,none, none, 12px);
}

@include media-breakpoint-up(xs) {
        //responsive-input($ziseTextInput, $sizeTextSelect)
        @include responsive-input(12px, 10px);
}

@include media-breakpoint-up(sm) {
        //responsive-input($ziseTextInput, $sizeTextSelect)
        @include responsive-input(13px, 11px);
}

@include media-breakpoint-up(md) {
        //responsive-input($ziseTextInput, $sizeTextSelect)
        @include responsive-input(15px, 12px);
}

@include media-breakpoint-up(lg) {
        //responsive-input($ziseTextInput, $sizeTextSelect)
        @include responsive-input(17px, 13px);
}

@include media-breakpoint-up(xl) {
        //responsive-input($ziseTextInput, $sizeTextSelect)
        @include responsive-input(17px, 15px);
}

@include media-breakpoint-up(xxl) {
    //responsive-input($ziseTextInput, $sizeTextSelect)
    @include responsive-input(17px, 15px);
}