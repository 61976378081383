@mixin flex-component($display, $direction, $aling, $justify) {
  @if $display != none {
    display: $display;
  }
  @if $direction != none {
    flex-direction: $direction;
  }
  @if $aling != none {
    align-items: $aling;
  }
  @if $justify != none {
    justify-content: $justify;
  }
}

@mixin size-component($width, $height) {
  @if $width != none {
    width: $width;
  }
  @if $height != none {
    height: $height;
  }
}

@mixin margin-component($top, $bottom, $left, $right) {
  @if $top != none {
    margin-top: $top;
  }
  @if $bottom != none {
    margin-bottom: $bottom;
  }
  @if $left != none {
    margin-left: $left;
  }
  @if $right != none {
    margin-right: $right;
  }
}

@mixin text-component($aling, $color, $weight, $size) {
  @if $aling != none {
    text-align: $aling;
  }
  @if $color != none {
    color: $color;
  }
  @if $weight != none {
    font-weight: $weight;
  }
  @if $size != none {
    font-size: $size;
  }
}

@mixin border-component($size, $radius, $color) {
  @if $size != none {
    border: $size solid;
  }
  @if $radius != none {
    border-radius: $radius;
  }
  @if $color != none {
    border-color: $color;
  }
}

@mixin input-component() {
  outline: none;
  padding: 10px;
  background-color:#dededf;
  color:#2b4763;
}

@mixin responsive-edit(
  $widhtAvatar,
  $heightAvatar,
  $marginLeft,
  $widhtButton,
  $heightButton,
  $heightDivInput,
  $widhtForm,
  $sizeTextLabel,
  $sizeDivSelect,
  $sizeOptional,
  $sizeButtonVaccine
) {
  .edit__div-avatar {
    @include size-component($widhtAvatar, $heightAvatar);
  }
  .edit__div-button {
    @include margin-component($spacer * 5, $spacer * 3, $marginLeft, none);
    @include size-component($widhtButton, $heightButton);
  }
  .edit__div-input {
    @include size-component(none, $heightDivInput);
  }
  .edit__form {
    @include size-component($widhtForm, none);
  }
  .edit__label {
    @include text-component(none, none, none, $sizeTextLabel);
  }
  .edit__div-select {
    @include size-component(none, $sizeDivSelect);
  }
  .edit__optional {
    @include text-component(none, none, none, $sizeOptional);
  }
  .edit__email-adicional-info {
    @include text-component(center, $colorLabel, bold, $sizeOptional);
  }
  .edit__button-vaccine {
    width: $sizeButtonVaccine !important;
  }
}

@mixin response-edit-1($widhtButtonAvatar, $colLateral, $colBody, $sizeTextSwitch, $widhtHeightSwitch, $textButtonVaccine, $sizeContainerIconZoom, $sizeIconZoom, $widhtImageVaccine, $heightImageVaccine) {
  .edit__container-button-avatar {
    @include size-component($widhtButtonAvatar, none);
  }
  .edit__lateral {
    @include make-col($colLateral);
  }
  .edit__body {
    @include make-col($colBody);
  }
  .edit__text-switch {
    @include text-component(none, none, none, $sizeTextSwitch);
  }
  .edit__icon-switch {
    @include size-component($widhtHeightSwitch, $widhtHeightSwitch);
  }
  .edit-div-button-vaccine-text {
    @include text-component(none, #fff, bold, $textButtonVaccine);
  }
  .edit__container-icon-zoom div {
    @include size-component($sizeContainerIconZoom, $sizeContainerIconZoom);
  }
  .edit__div-icon-zoom {
    @include margin-component(-$sizeContainerIconZoom, none, none, none);
  }

  .edit__icon-zoom {
    @include size-component($sizeIconZoom, $sizeIconZoom);
  }
  .edit__div-image-vaccine {
    @include size-component($widhtImageVaccine, $heightImageVaccine);
  }
}

@mixin responsive-input($ziseTextInput, $sizeTextSelect) {
  .input__input {
    @include text-component(center, none, none, $ziseTextInput);
  }
  .input__select {
    @include text-component(none, none, none, $sizeTextSelect);
  }
}

@mixin responsive-view(
  $widhtImageAvatar,
  $colInformation,
  $directionInformation,
  $heightContainerInformation,
  $directionContainerInformation,
  $colInformationUser,
  $directionInformationUser,
  $marginBottonAvatar,
  $widthButtonTab,
  $heidhtButtonTab,
  $colInfoAditional,
  $directionContainerAditional,
  $widthButtonSOS,
  $marginButtonSOS,
  $viewIconEmergency,
  $widthAvatar,
  $heightAvatar,
) {
  .view__image-avatar {
    @include size-component($widhtImageAvatar, auto);
  }
  .view__div-information {
    @include make-col($colInformation);
    @include flex-component(flex, $directionInformation, none, none);
  }
  .view__container-information {
    @include size-component(100vw, $heightContainerInformation);
    @include flex-component(flex, $directionContainerInformation, none, none);
  }
  .view__div-information-user {
    @include make-col($colInformationUser);
    @include flex-component(flex, $directionInformationUser, center, center);
  }
  .view__div-avatar {
    @include size-component($widthAvatar, $heightAvatar);
    @include margin-component($spacer * 2, $marginBottonAvatar, none, none);
  }
  .view__container-buttons-tab {
    @include size-component($widthButtonTab, $heidhtButtonTab);
  }
  .view__div-information-aditional {
    @include make-col($colInfoAditional);
  }
  .view__container-aditional-information {
    @include flex-component(flex, $directionContainerAditional, none, none);
  }
  .view__button-SOS {
    @include size-component($widthButtonSOS, $widthButtonSOS);
    @include margin-component(none, $marginButtonSOS, none, none);
  }
  .view__icon-emergency {
    width: $viewIconEmergency;
    height: $viewIconEmergency;
  }
}

@mixin responsive-view-1(
  $widhtHeightIcon,
  $widhtDiseasesAllergiesDiv,
  $directionDiseasesAllergies,
  $heightContainerDiseasesAllergies,
  $heightDiseasesAllergies,
  $colDiseasesAllergies,
  $sizeTitle,
  $sizeText,
  $colIconPhone,
  $widhtDivPhone,
  $sizeSubtitle,
  $widhtContainerAditional,
  $sizeTextEmergency,
  $sizeDescriptionEmergency,
  $heightContainerFooter,
  $heightContainer,
  $widhtVaccine,
  $heightVaccine
) {
  .view__icon {
    @include size-component($widhtHeightIcon, $widhtHeightIcon);
  }
  .view__div-diseases-allergies div {
    @include size-component($widhtDiseasesAllergiesDiv, 100%);
  }
  .view__container-diseases-allergies {
    @include flex-component(flex, $directionDiseasesAllergies, none, none);
    @include size-component(100vw, $heightContainerDiseasesAllergies);
  }
  .view__div-diseases-allergies {
    @include size-component(none, $heightDiseasesAllergies);
    @include make-col($colDiseasesAllergies);
  }
  .view__title-information {
    @include text-component(none, none, none, $sizeTitle);
  }
  .view__text-information {
    @include text-component(none, none, none, $sizeText);
  }
  .view__icon-phone {
    @include make-col($colIconPhone);
  }
  .view__icon-what {
    @include make-col($colIconPhone);
  }
  .view__div-phone {
    @include size-component($widhtDivPhone, auto);
  }
  .view__subtitle {
    @include text-component(none, $colorSubtitle, none, $sizeSubtitle);
  }
  .view__container-aditional-information {
    @include size-component($widhtContainerAditional, auto);
  }
  .view__text-SOS {
    @include text-component(none, none, bold, $sizeTextEmergency);
  }
  .view__title-button-emergency {
    @include text-component(center, none, bold, $sizeDescriptionEmergency );
  }
  .view__container-footer {
    @include size-component(100vw, $heightContainerFooter);
  }
  .view__container {
    min-height: (100vh - $heightContainer);
  }
  .view__photo-vaccine {
    @include size-component($widhtVaccine,$heightVaccine);
  }
}

@mixin responsive-view-2($displayProfession, $marginTopCompany, $sizeImageVCard, $widthButtonMenu){
  .view__label-profession{
    display: $displayProfession;
  }
  .view__div-company{
    @include margin-component($marginTopCompany, none, none, none);
  }
  .view__image-virtual-card{
    @include size-component($sizeImageVCard, auto);
  }
  .view__button-menu{
    @include size-component($widthButtonMenu, 30px);
  }
}
