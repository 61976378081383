@import "../base/settings.scss";
@import "../base/functions";

.index__container-icon{
    @include flex-component(flex, column, center, none);
}

.index__header-body{
    @include size-component(none, 10vh);
    @include flex-component(flex, column, center, center);
    background-color: $colorFooter;
    @include margin-component(none, $spacer*3, none, none);
}

.index__header-text{
    @include text-component(none, #fff, bold, none);
}

.index__text-description{
    @include text-component(center, none, bold, none);
}