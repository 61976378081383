@import "../base/settings.scss";
@import "../base/functions";

.formCard__form{
    @include make-col(6);
    @include flex-component(flex, column, none, none);
}

.formCard__input{
    @include size-component(60%,none);
    @include border-component(1px, none, black);
}