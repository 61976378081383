@import "../base/settings.scss";
@import "../base/functions";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.view__address{
  @include text-component(none, $colorLabel, bold, 70%);
  @include margin-component(-7px, none, none, none);
}

.view__avatar {
  @include size-component(10%, auto);
}

.view__body {
  @include size-component(none, auto);
}

.view__button-menu{
  @include size-component(30%, 30px);
  @include text-component(center, white, bold, 13px);
  @include margin-component($spacer*0.5, $spacer*0.5, none, none);
  @include flex-component(flex, column, none, center);
  border-radius: 5px;
  cursor: pointer;
}

.view__buttons-aditional-contact{
  @include flex-component(flex, row, none, center);
}

.view__button-SOS{
  background: linear-gradient(red 40%, orange);
  @include border-component(none, 100px, none);
  @include size-component(80px, 80px);
  @include flex-component(flex, column, center, none);
  cursor: pointer;
  box-shadow: 0.5px 0.5px 0.5px 1px orange inset ;
  border: 1px solid orange;
  @include margin-component(none, 5px, none, none);
}

.view__button-SOS div{
  margin-top: 4px;
}

.view__button-tab-circle {
  @include border-component(0, 50px, none);
  @include size-component(10px, 10px);
  background-color: #fff;
}

.view__button-tab-left {
  background-color: $colorBodyEdit;
  @include border-component(1px, 50px 0 0 50px, $colorLabel);
  @include flex-component(flex, row, center, center);
  @include size-component(50%, auto);
}

.view__button-tab-right {
  background-color: $colorBodyEdit;
  @include border-component(1px, 0 50px 50px 0, $colorLabel);
  @include flex-component(flex, row, center, center);
  @include size-component(50%, auto);
}

.view__container{
  min-height: 80vh;
  @include size-component(100%,auto);
}

.view__container-name{
  @include flex-component(flex, column, center, center);
  @include margin-component($spacer, none, none, none);
}

.view__container-aditional-information {
  @include size-component(90%, auto);
  @include flex-component(flex, row, center, center);
  @include margin-component($spacer, $spacer, none, none);
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.275);
}

.view__container-button-phone{
  @include size-component(100%, auto);
  @include flex-component(flex, column, center, none);
}

.view__container-buttons-tab {
  @include flex-component(flex, row, none, none);
  @include size-component(25%, 6vh);
  cursor: pointer;
}

.view__container-diseases-allergies {
  @include size-component(100vw, 13vh);
  @include flex-component(flex, row, none, none);
  @include margin-component($spacer * 1.5, none, none, none);
}


.view__container-icon {
  @include make-col(2);
  @include flex-component(flex, row, center, center);
  @include margin-component(10px, none, none, none);
}

.view__container-information {
  @include size-component(100vw, 15vh);
  @include flex-component(flex, row, none, none);
  @include margin-component(10px, none, none, none);
}

.view__container-footer {
  @include size-component(100vw, 20vh);
  background-color: #ff0000;
  @include flex-component(flex, row, center, center);
  @include margin-component($spacer*0.5, none, none, none);
}

.view__container-line{
  @include flex-component(flex, row, none, none);
  @include size-component(80%, auto);
  @include margin-component(none, none, 10%, none);
}

.view__container-menu{
  @include flex-component(flex, column, center, none);
}

.view__container-modal-vaccine{
  @include size-component(100%, 50%);
  overflow: scroll;
}

.view__div-aditional-information{
  @include size-component(100%, auto);
  @include flex-component(flex, column, center, none);
}

.view__div-avatar {
  @include size-component(100vw, 140px);
  @include flex-component(flex, row, none, center);
  @include margin-component($spacer * 2, $spacer * 3, none, none);
}

.view__div-buttons-tab {
  @include flex-component(flex, row, none, center);
}

.view__div-diseases-allergies {
  @include make-col(6);
  @include flex-component(flex, row, none, center);
  @include margin-component(none, $spacer, none, none);
}

.view__div-diseases-allergies div {
  @include size-component(60%, 100%);
  @include flex-component(flex, column, center, none);
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.275);
  overflow: scroll;
}

.view__div-icon {
  @include make-col(4);
  cursor: pointer;
  @include flex-component(flex, none, center, center);
}

.view__div-information {
  @include make-col(2.4);
  @include flex-component(flex, column, none, none);
  @include margin-component($spacer*0.5, none, none, none);
}

.view__div-information-contact {
  @include make-col(6);
  @include flex-component(flex, column, center, none);
  @include margin-component($spacer, none, none, none);
}

.view__div-information-aditional {
  @include make-col(2.4);
  @include flex-component(flex, column, center, none);
  @include margin-component($spacer, none, none, none);
}

.view__div-information-user {
  @include make-col(12);
  @include flex-component(flex, row, center, center);
  @include margin-component(none, $spacer, none, none);
}

.view__div-line{
  @include size-component(33%, 10px);
  border: none;
}

.view__div-phone {
  @include size-component(35%, auto);
  @include flex-component(flex, column, center, center);
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.275);
  @include margin-component($spacer, $spacer*0.5, none, none);
}

.view__div-virtual-card{
  @include flex-component(flex, column, center, none);
  @include margin-component($spacer*1.5, none, none, none);
}

.view__icon {
  @include size-component(60%, 60%);
}

.view__icon-contact{
  @include flex-component(flex, none, none, center);
  @include margin-component(none, $spacer*0.5, none, none);
}

.view__icon-emergency{
  @include size-component(35px, 35px);
}

.view__icon-phone{
  @include make-col(11);
  @include flex-component(flex, row, center, flex-end);
  @include margin-component(none, none,none, $spacer);
}

.view__icon-what{
  @include make-col(11);
  @include flex-component(flex, row, center, none);
  @include margin-component(none, none, $spacer, none);
}

.view__icons-user {
  @include size-component(100vw, auto);
  @include flex-component(flex, row, none, center);
}

.view__image-avatar {
  @include size-component(16%, auto);
}

.view__image-virtual-card{
  @include size-component(17%, auto);
  @include margin-component(-$spacer*0.5, none, none, none);
  cursor: pointer;
}

.view__label-profession{
  color: #000;
}

.view__line-blue{
  background-color: $colorBlueLogo;
}

.view__line-gray{
  background-color: $colorGrayLogo;
}

.view__line-red{
  background-color: $colorRedLogo;
}

.view__name{
  @include text-component(none, $colorBlueLogo, bold, none);
}

.view__photo-vaccine{
  @include size-component(100%,100%);
}

.view__subtitle {
  @include text-component(none, $colorSubtitle, bold, none);
}

.view__text-button-tab {
  @include text-component(none, $colorLabel, none, 90%);
  @include margin-component(6px, none, 5px, 5px);
}

.view__text-SOS{
  @include text-component(none, none, bold, 70%);
} 

.view__text-information {
  @include text-component(center, $colorBlueLogo, none, none);
}

.view__title-footer {
  @include text-component(center, #fff, none, none);
  padding-top: 10px;
}

.view__title-icons{
  @include text-component(none, $colorBlueLogo, bold, none);
  @include margin-component($spacer, none, none, none);
}

.view__title-information {
  @include text-component(center, $colorBlueLogo, bold, none);
  @include margin-component(none, none, none, 4px);
}

.view__title-button-emergency{
  @include text-component(center, none, bold, none);
}


///////// RESPONSIVE ///////////

@include media-breakpoint-up(xs) {
  @include responsive-view(45%, 9, row, auto, column, 12, column, $spacer, 80%, 5vh, 12, column,50px,1px,25px, 100vw, 200px);
  @include responsive-view-1(100%, 80%, column, 23vh, 45%, 12, 100%, 90%, 5, 80%, 70%, 80%, 60%,80%, 8vh, 12vh, 98%, 30vh);
  @include responsive-view-2(none, -$spacer*0.1, 15%, 80%);
}

@include media-breakpoint-up(sm) {
  @include responsive-view(45%, 9, row, auto, column, 12, column, $spacer, 45%, 5vh, 12, column, 70px,3px, 40px, 100vw, 240px);
  @include responsive-view-1(85%, 80%, column, 23vh, 45%, 12, 90%, 80%, 8, 50%, 80%, 60%, 70%, 80%,10vh, 19vh, 99%, 30vh);
  @include responsive-view-2(block, $spacer, 19%, 80%);
}

@include media-breakpoint-up(md) {
  @include responsive-view(25%, 2.4, column, 15vh, row, 12, row, $spacer, 35%, 5vh, 2.4, row, 70px,4px, 45px, 100vw, 240px);
  @include responsive-view-1(60%, 80%, column, 23vh, 45%, 12, 90%, 80%, 9, 45%, 85%, 90%, 70%, 100%, 10vh, 13vh, 99%, 30vh);
  @include responsive-view-2(block, $spacer, 16%, 40%);
}

@include media-breakpoint-up(lg) {
  @include responsive-view(20%, 2.4, column, 15vh, row, 12, row, $spacer * 1.5, 25%, 5vh, 2.4, row, 80px,5px, 50px, 100vw, 240px);
  @include responsive-view-1(65%, 70%, row, 11vh, 100%, 6, 90%, 80%, 11, 35%, 90%, 90%, 70%, 100%, 13vh, 16vh, 100%, 40vh);
  @include responsive-view-2(block, $spacer, 20%, 40%);
}

@include media-breakpoint-up(xl) {
  @include responsive-view(15%, 2.4, column, 15vh, row, 12, row, $spacer * 3, 25%, 6vh, 2.4, row, 80px,5px, 50px, 100vw, 240px);
  @include responsive-view-1(50%, 60%, row, 11vh, 100%, 6, 100%, 90%, 11, 35%, 100%, 90%, 70%, 100%, 13vh, 22vh, 100%, 50vh);
  @include responsive-view-2(block, $spacer, 17%, 30%);
}

@include media-breakpoint-up(xxl) {
  @include responsive-view(15%, 2.4, column, 15vh, row, 12, row, $spacer * 3, 25%, 6vh, 2.4, row,80px,5px, 50px, 100vw, 240px);
  @include responsive-view-1(50%, 60%, row, 11vh, 100%, 6, 100%, 90%, 11, 35%, 100%, 90%,70%,100%, 13vh, 18vh, 100%, 60vh);
  @include responsive-view-2(block, $spacer, 17%, 30%);
}
