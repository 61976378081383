@import "../base/settings.scss";
@import "../base/functions";

.response__text-description-suscription {
  @include text-component(center, none, bold, none);
}

///////// RESPONSIVE ///////////

@include media-breakpoint-up(xs) {
  .response__text-description-suscription {
    @include text-component(center, none, bold, 60%);
  }
}

@include media-breakpoint-up(sm) {
  .response__text-description-suscription {
    @include text-component(center, none, bold, 70%);
  }
}

@include media-breakpoint-up(md) {
  .response__text-description-suscription {
    @include text-component(center, none, bold, 90%);
  }
}

@include media-breakpoint-up(lg) {
  .response__text-description-suscription {
    @include text-component(center, none, bold, 100%);
  }
}

@include media-breakpoint-up(xl) {
  .response__text-description-suscription {
    @include text-component(center, none, bold, 100%);
  }
}

@include media-breakpoint-up(xxl) {
  .response__text-description-suscription {
    @include text-component(center, none, bold, none);
  }
}
