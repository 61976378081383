@import "../base/settings.scss";
@import "../base/functions";

.terms__show-text{
    @include text-component(center, none, bold, 90%);
    text-decoration: underline;
    cursor: pointer;
}

.terms__subtitle{
    @include text-component(center, none, bold, none);
}

.terms__text{
    @include text-component(center, none, lighter, 70%);
}