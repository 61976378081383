*{
    font-family: Helvetica, Arial, sans-serif;
    margin: 0px;
    padding: 0px;
}

html, body{
    height: auto;
    width: 100vw;
}
