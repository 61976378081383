
// colos

$colorBodyEdit: #EFEFEF;
$colorFooter:#FF0000;
$colorBodyView:#fff;
$colorLabel:#7D7D7D;
$colorSubtitle:#000;
$colorLoading:#F27200;
$colorBlueLogo:#2b4763;
$colorGrayLogo:#dededf;
$colorRedLogo:#9d1b3b;

