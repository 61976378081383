@import "../base/settings.scss";
@import "../base/functions";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.edit__body {
  @include make-col(8);
  @include size-component(none, auto);
  @include flex-component(flex, column, center, none);
  background-color: #fff;
}

.edit__button {
  @include size-component(100%, 100%);
  @include border-component(0, 50px, none);
  @include text-component(none, #fff, bold, none);
  background-color: $colorBlueLogo;
}

.edit__button-vaccine {
  @include flex-component(flex, none, none, center);
  @include margin-component($spacer, none, none, none);
}

.edit__container {
  @include flex-component(flex, row, none, none);
}

.edit__container-button-avatar {
  @include size-component(40%, none);
  @include margin-component(15px, none, none, none);
}

.edit__container-icon-zoom {
  border-radius: 20px 0px 0px 0px;
}

.edit__container-icon-zoom div {
  @include size-component(50px, 50px);
  @include flex-component(flex, column, center, center);
}

.edit__container-input {
  @include make-col(10);
}

.edit__container-avatar-vaccine {
  @include flex-component(flex, column, center, none);
  @include margin-component(none, none, 10px, 10px);
}

.edit__container-group-photo-vaccine {
  @include flex-component(flex, row, none, center);
}

.edit__div-avatar {
  @include margin-component($spacer * 3, $spacer, none, none);
  @include size-component(15vw, 25vh);
  background-color: #fff;
}

.edit__div-button {
  @include margin-component($spacer * 5, $spacer * 3, 25%, none);
  @include size-component(50%, 7vh);
}

.edit__div-button-avatar {
  @include flex-component(flex, none, center, center);
  @include size-component(100%, 5vh);
  @include border-component(0,50px,none);
  @include text-component(none, #fff, bold, 15px);
  @include margin-component(none, $spacer, none, none);
  background-color: $colorBlueLogo;
  cursor: pointer;
}

.edit-div-button-vaccine-text {
  font-size: 10px !important;
}

.edit__div-field {
  @include make-col(1);
  @include flex-component(flex, column, center, center);
  @include margin-component($spacer, none, none, none);
}

.edit__div-fiel-input {
  @include flex-component(flex, row, none, none);
}

.edit__div-icon-zoom {
  @include margin-component(-50px, none, none, none);
  @include flex-component(flex, column, end, none);
  @include size-component(100%, none);
  cursor: pointer;
}

.edit__div-image-vaccine{
  @include size-component(200px, 150px);
}

.edit__div-input {
  @include size-component(100%, 5vh);
  @include margin-component(none, $spacer, none, none);
}

.edit__div-select {
  @include size-component(none, 5vh);
  @include margin-component(none, $spacer, none, none);
}

.edit__div-text-area {
  @include size-component(100%, 10vh);
  @include margin-component(none, $spacer, none, none);
}

.edit__div-vaccine {
  @include margin-component(-$spacer*2, none, none, none);
}

.edit__icon-switch {
  @include size-component(20px, 20px);
  @include margin-component($spacer, none, none, none);
}

.edit__icon-zoom {
  @include size-component(30px, 30px);
  @include margin-component(5px, none, 5px, none);
}

.edit__email-adicional-info {
  @include size-component(100%, none);
  @include text-component(center, $colorLabel, bold, 10px);
  @include margin-component(none, $spacer*0.25, none, none);
}

.edit__form {
  @include size-component(60%, none);
  @include margin-component($spacer, none, none, none);
}

.edit__label {
  @include size-component(100%, none);
  @include text-component(center, $colorBlueLogo, bold, none);
}

.edit__lateral {
  @include make-col(2);
  @include size-component(none, auto);
  background-color: $colorRedLogo;
}

.edit__optional {
  @include text-component(none, none, none, 10px);
}

.edit__photo {
  @include size-component(100%, 100%);
}

.edit__subtitle {
  @include text-component(center, $colorSubtitle, bold, none);
  @include margin-component($spacer *1.5, $spacer, none, none);
}

.edit__text-description-field {
  @include text-component(center, none, none, 50%);
}

.edit__text-switch {
  @include text-component(none, none, none, 70%);
}

.edit__switch {
  @include flex-component(flex, column, center, center);
  @include margin-component(-15px, none, $spacer, none);
  cursor: pointer;
}

///////// RESPONSIVE /////////

@include media-breakpoint-up(xs) {
  @include responsive-edit(40vw, 20vh, 5%, 90%, 7vh, 4vh, 90%, 10px, 35px, 6px,50%);
  @include response-edit-1(80%, 1, 10, 50%, 15px, 13px, 25px, 13px, 110px, 90px);
}

@include media-breakpoint-up(sm) {
  @include responsive-edit(28vw, 30vh, 10%, 80%, 7vh, 4.5vh, 80%, 11px, 4.5vh, 7px,40%);
  @include response-edit-1(60%, 1, 10, 50%, 15px, 15px, 30px, 15px, 160px, 120px);
}

@include media-breakpoint-up(md) {
  @include responsive-edit(30vw, 25vh, 15%, 70%, 7vh, 4.5vh, 75%, 12px, 4.7vh, 8px,30%);
  @include response-edit-1(60%, 1.5, 9, 60%, 20px, 16px, 35px, 20px, 170px, 130px);
}

@include media-breakpoint-up(lg) {
  @include responsive-edit(25vw, 20vh, 20%, 60%, 7vh, 4.5vh, 70%, 13px, 4.7vh,10px,30%);
  @include response-edit-1(60%, 2, 8, 60%, 20px, 15px, 40px, 25px, 190px, 140px);
}

@include media-breakpoint-up(xl) {
  @include responsive-edit(15vw, 25vh, 25%, 50%, 7vh, 5vh, 60%, 15px, 5vh,10px,30%);
  @include response-edit-1(60%, 2, 8, 70%, 20px, 15px, 40px, 25px, 200px, 150px);
}

@include media-breakpoint-up(xxl) {
  @include responsive-edit(15vw, 25vh, 25%, 50%, 7vh, 5vh, 60%, 15px,5vh,10px,30%);
  @include response-edit-1(60%, 2, 8, 70%, 20px, 15px, 40px, 25px, 200px, 150px);
}
